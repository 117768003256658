
import { FormGroup } from '@angular/forms';
import { BasicHelper } from '@app/helpers/basic.helper';
import { Notification } from '../models/notification.model';

export class NotificationHelper {
    static buildNotificationFromFormGroup(_fg: FormGroup): Notification {
        return BasicHelper.buildFromFormGroup(new Notification, _fg);
    }
    static buildNotificationFromJson(json: any): Notification {
        return BasicHelper.buildFromJson(new Notification, json);
    }
}
