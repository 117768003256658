import { FormGroup } from '@angular/forms';
import { BasicHelper } from '@app/helpers/basic.helper';
import { Role } from '../models/role.model';

export class RoleHelper {
    static buildRoleFromFormGroup(_fg: FormGroup): Role {
        return BasicHelper.buildFromFormGroup(new Role, _fg);
    }
    static buildRoleFromJson(json: any): Role {
        return BasicHelper.buildFromJson(new Role, json);
    }
}
