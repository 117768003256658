import { FormGroup } from '@angular/forms';
import { BasicHelper } from '@app/helpers/basic.helper';
import { Action } from '../models/action.model';

export class ActionHelper {
    static buildActionFromFormGroup(_fg: FormGroup): Action {
        return BasicHelper.buildFromFormGroup(new Action, _fg);
    }
    static buildActionFromJson(json: any): Action {
        return BasicHelper.buildFromJson(new Action, json);
    }
}
